import React, { Fragment, useContext } from 'react'
import FormBody from '../formMultiStep/FormBody';
import FormDataContext from '../formMultiStep/context/formDataContext';
import { Container, Row, Col } from 'react-bootstrap';
import ChartBudget from './ChartBudget';

const CustomBody = () => {

    const formDataContext = useContext(FormDataContext);


    const { currentStep } = formDataContext;

    return (
        <Fragment>

            {
                currentStep === 'budgetInfo' ? <Container>
                    <Row>
                        <Col md={6}>

                            <FormBody />
                        </Col>
                        <Col>
                            <ChartBudget />
                        </Col>
                    </Row>
                </Container> : <Container>
                    <Row>
                        <Col md={12}>

                            <FormBody />
                        </Col>
                    </Row>
                </Container>
            }




        </Fragment>
    )
}

export default CustomBody