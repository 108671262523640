import React, { useContext } from 'react'
import { Button } from 'react-bootstrap';
import FormDataContext from './context/formDataContext';
const FormFooter = () => {

    const formDataContext = useContext(FormDataContext);

    const { currentStep, isAnimating, getSchemaStepPostion, previousStep, nextStep, schema } = formDataContext


    return (
        <div className="d-flex justify-content-between">
            <Button
                className="edu-btn btn-medium"
                variant={getSchemaStepPostion(currentStep) === 1 ? "secondary" : "primary"}
                onClick={() => {
                    previousStep(currentStep)
                }}
                disabled={currentStep === 0 || isAnimating}
            >
                Previous
            </Button>
            <Button
                className="edu-btn btn-medium"

                variant={getSchemaStepPostion(currentStep) === schema.steps.length ? "success" : "primary"}
                onClick={() => {

                    nextStep(currentStep)
                }}
                disabled={isAnimating}
            >
                {getSchemaStepPostion(currentStep) === schema.steps.length ? 'Submit' : 'Next'}
            </Button>
        </div>)
}

export default FormFooter