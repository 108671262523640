import React, { useReducer } from 'react';
import { v4 as uuidv4 } from 'uuid';
import alertsContext from './alertsContext';
import alertsReducer from './alertsReducer';
import actions from './actions';
import { ALERT_TYPE } from 'utils/enums/index'

// const { v4: uuidv4 } = require('uuid');

const defaultContent = {
    title: null,
    message: null,
    icon: null,
    actions: null,
    type: ALERT_TYPE.INFO,
    // position: POSITION.TOP_CENTER, //For now alle alerts are in top positin centred
    dismissible: true,
}

const AlertsState = props => {
    // template of state

    const [state, dispatch] = useReducer(alertsReducer, []);

    //Dispatchers

    const addAlert = (content, timeout = null) => {

        const idAlert = uuidv4();
        console.log('id alert=>', idAlert)
        dispatch({
            type: actions.ADD_ALERT,
            payload: {
                ...defaultContent,
                ...content,
                id: idAlert,
            },
        });
        if (timeout) {
            setTimeout(() => {
                removeAlert(idAlert)
            }, timeout);
        }
    }


    const updateAlert = (id, content) => {

        dispatch({ type: actions.UPDATE_ALERT, id: id, payload: content })

    }
    const removeAlert = (id) => {
        dispatch({ type: actions.REMOVE_ALERT, id: id })
    }

    const removeAll = () => {
        dispatch({ type: actions.REMOVE_ALL })

    }

    //Functions

    const info = (content, timeout = null) => {
        addAlert({
            ...content,
            type: ALERT_TYPE.INFO
        }, timeout)
    }

    const success = (content, timeout = null) => {
        addAlert({
            ...content,
            type: ALERT_TYPE.SUCCESS
        }, timeout)
    }


    const warning = (content, timeout = null) => {
        addAlert({
            ...content,
            type: ALERT_TYPE.WARNING
        }, timeout)
    }

    const error = (content, timeout = null) => {

        addAlert({
            ...content,
            type: ALERT_TYPE.ERROR
        }, timeout)
    }

    return (
        <alertsContext.Provider
            value={{
                alerts: state,
                addAlert: addAlert,
                removeAlert: removeAlert,
                updateAlert: updateAlert,
                removeAll: removeAll,
                info: info,
                warning: warning,
                success: success,
                error: error
            }}
        >
            {props.children}
        </alertsContext.Provider>
    );
};

export default AlertsState;
