const form = {
    position: 2,
    id: "academicInfo",
    title: "Informations académiques",
    description: "Entrez les informations des études que vous prévoyez",
    icon: "GraduationCap",
    fields: [
        {
            id: "school",
            type: "select-other",
            name: "école",
            label: "Choix de l'école",
            placeholder: 'Tapez le nom de votre école',

            options: [
                { value: "INP-ENSEEIHT", label: "INP-ENSEEIHT" },
                { value: "INP-ENSIMAG", label: "INP-ENSIMAG" },
                { value: 'OTHERS', label: "Autres" }
            ],
            required: true
        },
        {
            id: "studyType",
            conditionalDisplay: true,
            type: "select-other",
            name: "typeEtudes",
            label: "Type d'études",
            placeholder: 'Tapez le type de vos études',

            options: [
                { value: "Finance", label: "Finance" },
                { value: "Ingenierie", label: "Ingénierie" },
                { value: 'OTHERS', label: "Autres" }

            ],
            required: true
        },
        {
            id: "studyYears",
            type: "number",
            name: "anneesEtudes",
            label: "Nombre d'années d'études",
            placeholder: "Entrez le nombre d'années",
            required: true,
            validation: {
                min: 1,
                max: 10
            }
        }
    ]
};

export default form;
