import React, { useState, useEffect } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import './style.css'
import { useTranslation } from 'react-i18next';

const LoanCalculator = () => {
    const [loanAmount, setLoanAmount] = useState(75000);
    const [loanDuration, setLoanDuration] = useState(15);
    const [interestRate, setInterestRate] = useState(2);
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const { t } = useTranslation();

    // Calculate monthly payment whenever amount, duration, or interest rate changes
    useEffect(() => {
        const monthlyRate = interestRate / (100 * 12); // Convert annual rate to monthly
        const totalMonths = loanDuration * 12;

        // Monthly payment calculation using the annuity formula
        const monthly = loanAmount > 0
            ? (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, totalMonths)) /
            (Math.pow(1 + monthlyRate, totalMonths) - 1)
            : 0;

        setMonthlyPayment(monthly.toFixed(2));
    }, [loanAmount, loanDuration, interestRate]); // Added interestRate dependency

    return (
        <Card className="loan-calculator shadow-sm p-4 " >
            <Card.Body>
                {/* Loan Amount Input */}
                <Form.Group className="mb-4 loan-amount ">
                    <Form.Label className="fw-bold mb-3 h6 ">
                        {t('loanCalculator.desiredAmount')}
                    </Form.Label>
                    <div className="position-relative">
                        <div className="position-relative">
                            <div className="input-container">
                                <Form.Control
                                    type="text"
                                    value={loanAmount}
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/\D/g, '');
                                        setLoanAmount(Number(value));
                                    }}
                                    className="form-control-lg border-0 bg-light mb-2"
                                    style={{
                                        fontSize: '24px',
                                        fontWeight: 'bold'
                                    }}
                                />
                            </div>
                        </div>

                        <input
                            type='range'
                            min={1}
                            max={75000}
                            value={loanAmount}
                            onChange={(e) => setLoanAmount(Number(e.target.value))}
                            className="w-100"
                        />
                    </div>
                </Form.Group>

                {/* Loan Duration Input */}
                <Form.Group className="mb-4 loan-duration">
                    <Form.Label className="fw-bold mb-3 h6 title">
                        {t('loanCalculator.duration')}
                    </Form.Label>
                    <div className="position-relative">
                        <div className="position-relative">
                            <div className="input-container">
                                <Form.Control
                                    type="text"
                                    value={loanDuration}
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/\D/g, '');
                                        setLoanDuration(Number(value));
                                    }}
                                    className="form-control-lg border-0 bg-light mb-2"
                                    style={{
                                        fontSize: '24px',
                                        fontWeight: 'bold'
                                    }}
                                />
                            </div>
                        </div>

                        <input
                            type='range'
                            min={1}
                            max={30}
                            value={loanDuration}
                            onChange={(e) => setLoanDuration(Number(e.target.value))}
                            className="w-100"
                        />
                    </div>
                </Form.Group>

                {/* Interest Rate Input - New Addition */}
                <Form.Group className="mb-4 interest-rate">
                    <Form.Label className="fw-bold mb-3 h6 title">
                        {t('loanCalculator.interestRate')}
                    </Form.Label>
                    <div className="position-relative">
                        <div className="position-relative">
                            <div className="input-container">
                                <Form.Control
                                    type="text"
                                    value={interestRate}
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/[^\d.]/g, '');
                                        setInterestRate(Number(value));
                                    }}
                                    className="form-control-lg border-0 bg-light mb-2"
                                    style={{
                                        fontSize: '24px',
                                        fontWeight: 'bold'
                                    }}
                                />
                            </div>
                        </div>

                        <input
                            type='range'
                            min={0}
                            max={15}
                            step={0.1}
                            value={interestRate}
                            onChange={(e) => setInterestRate(Number(e.target.value))}
                            className="w-100"
                        />
                    </div>
                </Form.Group>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <span className="text-muted h6">{t('loanCalculator.estimatedAmount')}:</span>
                    <span className="h5 mb-0">~ {monthlyPayment} €</span>


                </div>
                <p className='small mb-0'>{t('loanCalculator.notif1')}</p>
                <p className='small mt-0'>{t('loanCalculator.notif2')}</p>


                {!(process.env.REACT_APP_MODE_ACUISITION === "yes") &&
                    <Button
                        variant="primary"
                        className="w-100 edu-btn btn-medium"
                    >
                        Déposer ma demande →
                    </Button>}
            </Card.Body>
        </Card>
    );
};

export default LoanCalculator;