import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoadingSpinner = (props) => {
    return (
        <div
            style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 2000, // Ensure the spinner appears above other content

            }}
        >
            <Spinner animation="border" variant="primary" size="lg"
                style={{ width: `${props.width}px`, height: `${props.height}px`, zIndex: '9999999 !important' }}
            />
        </div>
    );
};

export default LoadingSpinner;