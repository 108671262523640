import React from 'react';
import { Container } from 'react-bootstrap';

const RandomGalleryImages = ({ images }) => {


    const getImageDimensions = () => {
        const count = images.length;

        // Handle cases 1-4
        if (count === 1) {
            return [{ width: '70%', height: '80%', top: '10%', left: '15%' }];
        }
        if (count === 2) {
            return [
                { width: '45%', height: '70%', top: '15%', left: '3%' },
                { width: '45%', height: '70%', top: '15%', left: '52%' }
            ];
        }
        if (count === 3) {
            return [
                { width: '45%', height: '45%', top: '5%', left: '27.5%' },
                { width: '45%', height: '45%', top: '50%', left: '3%' },
                { width: '45%', height: '45%', top: '50%', left: '52%' }
            ];
        }
        if (count === 4) {
            return [
                { width: '45%', height: '45%', top: '3%', left: '3%' },
                { width: '45%', height: '45%', top: '3%', left: '52%' },
                { width: '45%', height: '45%', top: '52%', left: '3%' },
                { width: '45%', height: '45%', top: '52%', left: '52%' }
            ];
        }

        // For more than 4 images, create a dynamic mosaic layout
        const positions = [];

        // Calculate dimensions based on number of images
        const baseWidth = count <= 6 ? '32%' : '28%';
        const baseHeight = count <= 6 ? '45%' : '40%';

        // Define possible position patterns
        const patterns = [
            // Left column
            { top: '5%', left: '2%' },
            { top: '52%', left: '2%' },
            // Middle column
            { top: '15%', left: '35%' },
            { top: '62%', left: '35%' },
            // Right column
            { top: '5%', left: '68%' },
            { top: '52%', left: '68%' },
            // Extra positions for more images
            { top: '28%', left: '18%' },
            { top: '28%', left: '52%' },
            { top: '40%', left: '35%' }
        ];

        // Feature image (slightly larger)
        positions.push({
            width: '38%',
            height: '50%',
            top: '25%',
            left: '31%',
            zIndex: 1
        });

        // Distribute remaining images
        for (let i = 1; i < count; i++) {
            const pattern = patterns[i - 1];
            positions.push({
                width: baseWidth,
                height: baseHeight,
                top: pattern.top,
                left: pattern.left,
                zIndex: 2
            });
        }

        return positions;
    };

    const getRandomTransform = () => {
        const rotation = Math.random() * 8 - 4; // rotation between -4 and 4 degrees
        const translateX = Math.random() * 40 - 20; // translation between -20px and 20px
        const translateY = Math.random() * 40 - 20; // translation between -20px and 20px
        
        return `rotate(${rotation}deg) translate(${translateX}px, ${translateY}px)`;
    };

    const dimensions = getImageDimensions();

    return (
        <Container
            fluid
            className="position-relative "
            style={{
                height: '700px',
                // minHeight: '700px',
                padding: '20px',
                overflow: 'hidden'
            }}
        >
            {images.map((image, index) => (
                <div
                    key={index}
                    className="position-absolute"
                    style={{
                        width: dimensions[index].width,
                        height: dimensions[index].height,
                        top: dimensions[index].top,
                        left: dimensions[index].left,
                        zIndex: dimensions[index].zIndex || 1,
                        transition: 'all 0.4s ease',
                        transform: getRandomTransform(),
                    }}
                >
                    <div className="w-100 h-100 p-2">
                        <img
                            src={image.src}
                            alt={image.alt}
                            className="w-100 h-100  rounded"
                            style={{
                                objectFit: 'contain',
                                transition: 'all 0.3s ease',
                            }}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.transform = 'scale(1.05)';
                                // e.currentTarget.parentElement.style.zIndex = '10';
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.transform = 'scale(1)';
                                e.currentTarget.parentElement.style.zIndex = dimensions[index].zIndex || 1;
                            }}
                        />
                    </div>
                </div>
            ))}
        </Container>
    );
};

export default RandomGalleryImages;