const schema = {
    id: 'personalInfo',
    position: 1,
    title: "Informations personnelles",
    description: "Entrez vos informations personnelles",
    icon: "User",
    name: "personalInfo",
    fields: [
        {
            id: "firstName",
            type: "text",
            name: "prenom",
            label: "Prénom",
            placeholder: "Entrez votre prénom",
            required: true,
            validation: {
                minLength: 2,
                maxLength: 50
            }
        },
        {
            id: "lastName",
            type: "text",
            name: "nom",
            label: "Nom",
            placeholder: "Entrez votre nom de famille",
            required: true,
            validation: {
                minLength: 2,
                maxLength: 50
            }
        },
        {
            id: "email",
            type: "email",
            name: "email",
            label: "Adresse e-mail",
            placeholder: "Entrez votre adresse e-mail",
            required: true
        },
        {
            id: "phoneNumber",
            type: "tel",
            name: "telephone",
            label: "Numéro de téléphone",
            placeholder: "Entrez votre numéro de téléphone",
            required: true,
            validation: {
                pattern: "^[0-9]{10}$",  // Example pattern for a 10-digit number
                message: "Le numéro de téléphone doit comporter 10 chiffres"
            }
        },
        {
            id: "address",
            type: "textarea",
            name: "adresse",
            label: "Adresse postal",
            placeholder: "Entrez votre adresse",
            required: true
        }
    ]
};


export default schema;