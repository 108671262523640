// useSectionContent.js

import { useTranslation } from 'react-i18next';

import { faBolt, faMapMarkerAlt, faSmile } from "@fortawesome/free-solid-svg-icons";


const useSectionContent = () => {
    const { t } = useTranslation();

    const content = {
        title: t('home.section.pretEtudiant.title'),
        description: t('home.section.pretEtudiant.description'),
        advantages: [
            {
                title: t('home.section.pretEtudiant.advantage1.title'),
                description: t('home.section.pretEtudiant.advantage1.description'),
                icon: faBolt, // You can use icons directly here
            },
            {
                title: t('home.section.pretEtudiant.advantage2.title'),
                description: t('home.section.pretEtudiant.advantage2.description'),
                icon: faMapMarkerAlt, // Another icon example
            },
          
        ],

        illustrations: [
            // { src: '/images/illustrations/student4.png', alt: '' },
            // { src: '/images/illustrations/student-looking-for-credit.webp', alt: '' },
            // { src: '/images/illustrations/student-looking-for-credit.webp', alt: '' },
        ]
    };

    return content;
};

export default useSectionContent;
