import React, { useContext } from 'react';
import { Alert } from 'react-bootstrap';
import AlertsContext from 'contexts/alerts/alertsContext';
import { ALERT_TYPE } from 'utils/enums/index';
import _ from 'lodash';


const mapToBootsrapVariant = {

    [ALERT_TYPE.INFO]: "info",
    [ALERT_TYPE.WARNING]: "warning",
    [ALERT_TYPE.ERROR]: "danger",
    [ALERT_TYPE.SUCCESS]: "success"
}
const Alerts = () => {
    const { alerts, removeAlert } = useContext(AlertsContext);

    return (
        <div
            style={{
                position: 'fixed',
                top: '10px',
                left: '50%',
                transform: 'translateX(-50%)', // Centers the container horizontally
                zIndex: 1050,
                maxWidth: '400px', // Optional: Adjust max width if needed
                width: '100%', // Ensures responsiveness
            }}
        >
            {alerts.map((alert) => (
                <Alert
                    key={alert.id}
                    variant={_.get(mapToBootsrapVariant, alert.type, 'info')}
                    onClose={() => removeAlert(alert.id)}
                    dismissible={alert.dismissible}
                >
                    {alert.message}
                </Alert>
            ))}
        </div>
    );
};

export default Alerts;