import apiClient from './apiClient';

const mod = 'subscribers';
const workflowAPI = {
    create: (data = {}) => {
        return apiClient.post(`/${mod}`, data);
    },


};

export default workflowAPI;
