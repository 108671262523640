import { faGift, faTrophy, faSmile } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';

const useOpenBanqAccountContent = () => {
    const { t } = useTranslation();

    const content = {
        preTitle: t('home.section.openBanqAccount.preTitle'),
        title: t('home.section.openBanqAccount.title'),

        action: (process.env.REACT_APP_MODE_ACUISITION === "yes") ? null : {
            title: t('home.section.openBanqAccount.actionTitle'),
            link: '#',
        },
        description: t('home.section.openBanqAccount.description'),
        advantages: [
            {
                title: t('home.section.openBanqAccount.advantage1.title'),
                description: t('home.section.openBanqAccount.advantage1.description'),
                icon: faGift, // Icon handled here
            },
            {
                title: t('home.section.openBanqAccount.advantage2.title'),
                description: t('home.section.openBanqAccount.advantage2.description'),
                icon: faTrophy, // Icon handled here
            },
            {
                title: t('home.section.pretEtudiant.advantage3.title'),
                description: t('home.section.pretEtudiant.advantage3.description'),
                icon: faTrophy, // Icon handled here
            }



        ],

        illustrations: [
            //    { src: '/images/illustrations/student4.png', alt: '' },
            // { src: '/images/illustrations/student2.png', alt: '' },
            // { src:  '/images/illustrations/student3.webp', alt: '' },
            // { src:  '/images/illustrations/student3.webp', alt: '' },
            { src: '/images/illustrations/student3.webp', alt: '' },
        ]
    };

    return content;
};

export default useOpenBanqAccountContent;