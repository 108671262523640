import React, { useContext, useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import { Container, Row, Card } from 'react-bootstrap';
import FormDataContext from '../formMultiStep/context/formDataContext';

import _ from 'lodash'

const constructData = (schema, fields) => {

    const data = schema.fields.map((field) => {

        return { value: Number(fields[field.id]) || 0, name: field.name }
    })


    console.log('data==>', data);
    return data;
}

const computeTotalBudgetPerMonth = (schema, fields) => {




    const sum = schema.fields.reduce((accu, field) => {

        return ((Number(fields[field.id]) || 0) + accu)
    }, 0);

    return sum;
}

const computeTotalBudgetPerYear = (schema, fields) => {
    return computeTotalBudgetPerMonth(schema, fields) * 12;
}

const computeTotalBudgetTotal = (schema, fields, number) => {
    return computeTotalBudgetPerYear(schema, fields) * number;
}



// Define an array of colors for each slice
const COLORS = [
    '#FF6B6B', // Soft Red
    '#4ECDC4', // Aqua
    '#556270', // Slate
    '#FF9F1C', // Orange
    '#2EC4B6', // Teal
    '#E71D36', // Red
    '#FFBF69', // Light Orange
    '#06D6A0', // Mint Green
    '#118AB2', // Blue
    '#073B4C', // Dark Blue
    '#FFD166', // Yellow
    '#8338EC', // Purple
    '#EF476F', // Pink
    '#A1C3D1', // Soft Blue
    '#4E89AE', // Steel Blue
    '#4361EE', // Royal Blue
    '#F72585', // Magenta
    '#B5179E', // Pinkish Purple
    '#FF66D8', // Bright Pink
    '#3A86FF'  // Vivid Blue
];


const ChartBudget = () => {

    const formDataContext = useContext(FormDataContext);
    const { getSchemaStep, fields } = formDataContext;
    const schemaBudget = getSchemaStep('budgetInfo');

    const [data, setData] = useState(constructData(schemaBudget, fields));
    useEffect(() => {
        setData(constructData(schemaBudget, fields))
    }, [fields, schemaBudget])

    const studyYears = fields.studyYears || 1


    return (<Container >

        <Row className='mx-auto'>
            <Container className='mx-auto'>
                <PieChart className='mx-auto' width={400} height={400}>

                    <Pie
                        data={data}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={120}
                        fill="#8884d8"
                        label
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>
            </Container>
        </Row>

        <Row className='mt-5 centred'>
            <Card >
                <Card.Body className='mx-auto'>
                    <Card.Title>Estimation Budget</Card.Title>
                    <Card.Text className='mx-auto'>
                        <ul className='mx-auto'>
                            <li className='mx-auto'>
                                <strong>Budget Total <small>/mois</small></strong> :{computeTotalBudgetPerMonth(schemaBudget, fields)}

                            </li>
                            <li>
                                <strong>Budget Total <small>/years</small></strong> :{computeTotalBudgetPerYear(schemaBudget, fields)}

                            </li>
                            <li>
                                <strong>Total buget <small>/{studyYears} année(s) d'études</small>  </strong> :{computeTotalBudgetPerMonth(schemaBudget, fields, studyYears)}

                            </li>
                        </ul>
                    </Card.Text>
                </Card.Body>
            </Card>

        </Row>
    </Container>)
};

export default ChartBudget;
