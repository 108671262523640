import React from 'react';


const AnimatedStep = ({ children, isVisible, direction }) => {
    const baseStyle = {
        transition: 'all 0.3s ease-in-out',
        transform: isVisible ? 'translateX(0)' : `translateX(${direction === 'right' ? '100%' : '-100%'})`,
        opacity: isVisible ? 1 : 0,
    };

    return (
        <div style={baseStyle}>
            {children}
        </div>
    );
};

export default AnimatedStep;