import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import NewsLetterSubscribe from 'components/newslettersSubscribe/index';
import './style.css'
const BannerLanding = () => {

    const { t } = useTranslation();


    return (
        <div className="banner-landing slider-area banner-style-2 bg-image d-flex align-items-center">
            <div className="container">

                <div className="row g-5 align-items-center position-relative">
                    <div className="col-lg-6 col-md-12">
                        <div className="inner">
                            <div className="content">
                                <h1 className="title">{t('home.landing.h1')} </h1>
                                <p className="description">{t('home.landing.description')}.</p>

                                <div class="container">
                                    {
                                        process.env.REACT_APP_MODE_ACUISITION === "yes" ? <Fragment>
                                            <NewsLetterSubscribe />

                                        </Fragment> :

                                            <div class="d-flex flex-column align-items-left gap-3">
                                                <Link className={'edu-btn mb-3 text-center'} to="#" >
                                                    {t('home.landing.link.pretEtudiant')}
                                                </Link>

                                                <Link className={'edu-btn mb-3 text-center'} to="#">
                                                    {t('home.landing.link.budgetSimulation')}
                                                </Link>

                                                <Link className={'edu-btn mb-3 text-center'} to="#" >
                                                    {t('home.landing.link.openBanqAccount')}
                                                </Link>

                                            </div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 h-100 position-relative" >

                        <div className="d-lg-block d-none">
                            <img src="/images/illustrations/girl-student.png" alt="Girl Background" style={{ height: '500px', width: 'auto', objectFit: 'cover' }} />
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-19.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-05-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-19-01.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BannerLanding;