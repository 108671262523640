import { useState, useContext } from 'react';
import API from 'api/index'
import AlertsContext from 'contexts/alerts/alertsContext';


function useSubscribers(mobileMaxWidth = 768) {

    const alertsContext = useContext(AlertsContext);

    const [pending, setPending] = useState(false);
    const [alerts, setAlerts] = useState([]);


    const preRun = () => {
        setPending(true);
    }
    const postRun = () => {
        setPending(false);
    }

    const onSuccess = () => {

        alertsContext.success({
            message: 'Subscriber created successfully'
        }, 3000)

    }
    const onFail = (err) => {

        alertsContext.success({
            message: err || 'Oops something goes wrong'
        }, 3000)

    }


    const createSubscriber = async (data) => {
        preRun();
        try {
            const res = await API.subscribers.create(data);

            postRun();
            onSuccess();
            return {
                data: res.data,
                error: null
            }
        }
        catch (err) {
            console.log('err=>', err);
            postRun();
            onFail();
            return {
                error: err
            }


        }
    }

    return { createSubscriber, pending };
}

export default useSubscribers;