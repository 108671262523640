import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const itemsNav = [{
    id: 'pret_etudiant',
    title: 'layout.header.pretEtudiant',
    items: null
},
{
    id: 'ouverture_compte',
    title: 'layout.header.countOpening',
    items: null
},
{
    id: 'simulator_budget',
    title: 'layout.header.budgetSimulator',
    items: null
},
{
    id: 'how_its_work',
    title: 'layout.header.howWork',
    items: null
},
{
    id: 'faq',
    title: 'layout.header.faq',
    items: null
}]

const Nav = () => {

    const { t } = useTranslation();

    return (
        <ul className="mainmenu">
            {
                itemsNav.map((itemNav) => {
                    return <li key={itemNav.id}>

                        <Link to="#">{t(itemNav.title)}</Link>
                        {itemNav.items && <ul className="submenu">
                            {
                                itemNav.items.map((item) => {
                                    return <li key={item.id}>
                                        <Link to="#">{t(item.title)}</Link>
                                    </li>
                                })
                            }
                        </ul>
                        }
                    </li>
                })

            }

        </ul >
    )
}
export default Nav;
