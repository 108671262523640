import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import supportedLanguages from "supportedLanguages";


// Get the current timestamp and modify it to change only once per hour
const getHourlyTimestamp = () => {
    const now = new Date();
    now.setMinutes(0, 0, 0); // Set minutes and seconds to zero
    return now.getTime();
};
i18next
    .use(HttpApi) // Registering the back-end plugin
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // Remove resources from here
        // lng: "en",
        ns: ['translation'],
        load: 'languageOnly',
        supportedLngs: supportedLanguages.array,
        nonExplicitSupportedLngs: true,
        defaultNS: 'translation',
        fallbackLng: 'fr',
        lng: 'fr',
        interpolation: {
            escapeValue: false,
        },
        debug: process.env.NODE_ENV === "development",
        detection: {
            // Order and from where user language should be detected
            order: ['path'], //, 'cookie', 'localStorage', 'navigator', 'htmlTag', 'querystring'

            // Keys or params to lookup language from
            lookupFromPathIndex: 0,

            // Cache user language on
            caches: ['localStorage', 'cookie'],

            // Optional expire and domain for set cookie
            cookieMinutes: 10,
            cookieDomain: 'myDomain',

            // URL options
            checkWhitelist: true, // Ensure the detected language is in the supportedLngs list
        },
        backend: {
            // Specify your backend server URL here
            loadPath: '/locales/{{lng}}/{{ns}}.json',
            // Adding queryStringParams to append a timestamp to the request URL
            queryStringParams: { v: getHourlyTimestamp() }   // Cache busting by timestamp
        }

    });

export default i18next;
