import React from 'react'
import FormDataState from '../formMultiStep/context/formDataState'
import { Container, Form, Button } from 'react-bootstrap';
import StepsIndicator from '../formMultiStep/StepsIndicator';
import FormHeader from '../formMultiStep/FormHeader';
import FormFooter from '../formMultiStep/FormFooter';
import CustomBody from './CustomBody';

const CreditMultiStepForm = (props) => {

    const { schema, onSubmit } = props;
    return (
        <FormDataState schema={schema} onSubmit={onSubmit}>
            <Container className="py-5">
                <div className=" p-4 rounded ">
                    {schema.title && (
                        <h2 className="text-center mb-4">{schema.title}</h2>
                    )}

                    {schema.description && (
                        <p className="text-center mb-4 text-muted">{schema.description}</p>
                    )}
                    <StepsIndicator />
                    <FormHeader />
                    <CustomBody />
                    <FormFooter />
                </div>
            </Container>
        </FormDataState>)
}

export default CreditMultiStepForm