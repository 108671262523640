const ALERT_TYPE = {
    INFO: 'INFO',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    WARNING: 'WARNING',
}


export {
    ALERT_TYPE
}