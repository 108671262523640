// src/api/apiClient.js

import axios from 'axios';
import { API_URL, VERSION_API } from 'utils/constant/index';

const apiClient = axios.create({
    baseURL: `${API_URL}/${VERSION_API}`,
    headers: {
        'Content-Type': 'application/json',
    },
});

let authToken = null;

export const setAuthToken = (token) => {
    authToken = token;
};

apiClient.interceptors.request.use(
    (config) => {
        if (authToken) {
            config.headers['x-auth-token'] = authToken;
        } else {
            delete config.headers['x-auth-token'];
        }
        return config;
    },
    (error) => Promise.reject(error) 
);

apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        // Handle common response errors 
        return Promise.reject(error);
    }
);

export default apiClient;
