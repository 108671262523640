import React, { Fragment } from 'react';

import { Form } from 'react-bootstrap';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import FeatherIcon from 'feather-icons-react';
import './style.css'

const GenericFormField = ({ field, value, onChange }) => {
    const renderField = () => {
        switch (field.type) {
            case 'text':
            case 'email':
            case 'password':
            case 'number':
                return (


                    <Form.Group className="position-relative">
                        <Form.Label>
                            {field.label} <span className="text-danger">*</span>
                        </Form.Label>
                        <div className="position-relative">
                            <Form.Control
                                type={field.type}
                                className="form-control pl-5"
                                errorMessage="Invalid Email"
                                onChange={(e) => onChange(e.target.value)}
                                placeholder={field.placeholder}
                                name="email"
                                required
                            />
                            {field.icon && <i className="position-absolute top-50 start-0 translate-middle-y ms-3">
                                <FeatherIcon icon={field.icon} className="fea icon-sm icons" />
                            </i>}
                        </div>
                    </Form.Group>
                );
            case 'tel':
                return (
                    <Form.Group className="position-relative">
                        <Form.Label>
                            {field.label} <span className="text-danger">*</span>
                        </Form.Label>
                        <PhoneInput
                            placeholder="Enter phone number"
                            value={value}
                            defaultCountry="fr"
                            onChange={(v) => onChange(v)}
                            required={onChange}

                        />
                    </Form.Group>
                )

            case 'textarea':
                return (

                    <Form.Group className="position-relative">
                        <Form.Label>
                            {field.label} <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={field.rows || 3}
                            placeholder={field.placeholder}
                            value={value || ''}
                            onChange={(e) => onChange(e.target.value)}
                            // isInvalid={!!errors?.[field.name]}
                            required={field.required}
                        />
                    </Form.Group>
                );

            case 'select':
                return (
                    <Form.Group className="position-relative">
                        <Form.Label>
                            {field.label} <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                            value={value || ''}
                            onChange={(e) => onChange(e.target.value)}
                            // isInvalid={!!errors?.[field.name]}
                            required={field.required}
                        >
                            <option value="">{field.placeholder || 'Select an option'}</option>
                            {field.options?.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                );

            case 'select-other':
                return (
                    <Fragment>
                        <Form.Group className="position-relative">
                            <Form.Label>
                                {field.label} <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                                value={value || ''}
                                onChange={(e) => onChange(e.target.value)}
                                // isInvalid={!!errors?.[field.name]}
                                required={field.required}
                            >
                                <option value="">{field.placeholder || 'Select an option'}</option>
                                {field.options?.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Select>
                            {
                                value === 'OTHERS' && 
                                    <Form.Control
                                        type='text'
                                        className='mt-4  ms-5'
                                        style={{ width: '80%' }}
                                        errorMessage="Invalid Email"
                                        label="toto"
                                        // onChange={(e) => onChange(e.target.value)}
                                        placeholder={field.placeholder}
                                        required
                                    />
                              
                            }
                        </Form.Group>

                    </Fragment>
                );

            case 'radio':
                return (
                    <div>
                        {field.options?.map((option) => (
                            <Form.Check
                                key={option.value}
                                type="radio"
                                id={`${field.name}-${option.value}`}
                                label={option.label}
                                name={field.name}
                                value={option.value}
                                checked={value === option.value}
                                onChange={(e) => onChange(field.name, e.target.value)}
                                // isInvalid={!!errors?.[field.name]}
                                required={field.required}
                            />
                        ))}
                    </div>
                );

            case 'checkbox':
                return (
                    <Form.Check
                        type="checkbox"
                        id={field.name}
                        label={field.checkboxLabel || field.label}
                        checked={value || false}
                        onChange={(e) => onChange(field.name, e.target.checked)}
                        // isInvalid={!!errors?.[field.name]}
                        required={field.required}
                    />
                );

            case 'range':
                return (
                    <div>
                        <Form.Range
                            min={field.min}
                            max={field.max}
                            step={field.step || 1}
                            value={value || field.defaultValue || field.min}
                            onChange={(e) => onChange(field.name, e.target.value)}
                        />
                        <div className="d-flex justify-content-between">
                            <small>{field.min}</small>
                            <small>{value || field.defaultValue}</small>
                            <small>{field.max}</small>
                        </div>
                    </div>
                );

            default:
                return <p>Unsupported field type: {field.type}</p>;
        }
    };

    return (
        <Form.Group className="mb-3">

            {renderField()}
            {/* {errors?.[field.name] && (
                <Form.Control.Feedback type="invalid">
                    {errors[field.name]}
                </Form.Control.Feedback>
            )}
            {field.helpText && (
                <Form.Text className="text-muted">
                    {field.helpText}
                </Form.Text>
            )} */}
        </Form.Group>
    );
};

export default GenericFormField;