import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from 'components/sectionTitle/index';
import './style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RandomGalleryImages from 'components/randomGalleryImages/index';
import useIsMobile from 'hooks/useIsMobile/index';

const SectionSolution = (props) => {


    const { illustrationLeft = true,
        content,
        children
    } = props;

    const { preTitle, title, action, description, advantages = [], illustrations = [] } = content;
    const isMobile = useIsMobile();

    return (
        <div className="edu-about-area mt-5" >
            <div className="container eduvibe-animated-shape">
                <div className="row g-5 align-items-center">

                    <div className={`col-lg-6 p-4 ${illustrationLeft ? 'order-first' : 'order-last'} position-relative d-flex justify-content-end`} >

                        {
                            children ? <div className="responsive-div-children mx-auto mt-5 " >

                                {children}
                            </div> :
                                <Fragment>
                                    {!isMobile &&
                                        <RandomGalleryImages images={illustrations} />
                                    }
                                </Fragment>
                        }








                    </div>

                    <div className="col-lg-6">
                        <div className="inner">
                            <SectionTitle
                                classes="text-start"
                                slogan={preTitle}
                                title={title}
                            />

                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}
                            >
                                <p className="description mt--40">{description}</p>
                            </ScrollAnimation>

                            <div className="feature-style-5 row g-5">
                                {
                                    advantages.map((advantage, idx) => {
                                        return <Fragment key={idx}> <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            className="col-lg-12 col-xl-6"
                                            animateOnce={true}
                                        >
                                            <div className="edu-feature-list">
                                                <div className="icon">
                                                    {
                                                        <FontAwesomeIcon icon={advantage.icon} className='text-light fa-xl' />
                                                    }
                                                </div>
                                                <div className="content">
                                                    <h6 className="title">{advantage.title}</h6>
                                                    {!isMobile && <p className="description">{advantage.description}</p>}
                                                </div>
                                            </div>
                                        </ScrollAnimation>
                                        </Fragment>
                                    })
                                }

                            </div>
                            {action &&
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="read-more-btn mt--60 mt_lg--30 mt_md--30 mt_sm--30"
                                    animateOnce={true}
                                >
                                    <div className="d-flex justify-content-center">

                                        <Link className="edu-btn" to={action.link}>{action.title} <i className="icon-arrow-right-line-right"></i></Link>
                                    </div>
                                </ScrollAnimation>
                            }
                        </div>
                    </div>
                </div>


            </div>
        </div >
    )
}

export default SectionSolution