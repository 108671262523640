import React from 'react'
import { Fragment } from 'react'
import Header from 'containers/header/index'
import BannerLanding from 'containers/bannerLanding/index';

import ContactUsLanding from 'containers/contactUsLanding/index';
import Footer from 'containers/footer/index';

import CreditMultiStepForm from 'components/creditMultiStepForm/index'

import schema from 'schema/index';

import { Container, Row, Col } from 'react-bootstrap'
const PretEtudiant = () => {
    return (
        <Fragment>
            <Header styles="header-transparent header-style-2" />
            {/* <BannerLanding /> */}


            <div >
                <Row style={{ paddingTop: '180px' }}>
                    <Col md={4}>
                        <div className="d-lg-block d-none">
                            <img style={{height:'800px'}} src="/images/banner/student-looking-for-credit.webp" alt="Girl Background" />
                        </div>
                    </Col>
                    <Col md={8}>
                        <CreditMultiStepForm schema={schema} />
                    </Col>
                </Row>
            </div>


            <ContactUsLanding />
            <Footer />
        </Fragment>
    )
}

export default PretEtudiant