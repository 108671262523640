import actions from './actions';
const reducerFunc = (state, action) => {
    switch (action.type) {
        case actions.SET_PENDING:
            return {
                ...state,
                pending: action.payload,
            };
        case actions.SET_CURRENT_STEP:
            return {
                ...state,
                currentStep: action.payload,
            };

        case actions.SET_FIELD:
            return {
                ...state,
               fields: {
                    ...state.fields,
                    [action.id]: action.payload
                },
            };
        case actions.SET_IS_ANIMATING:
            return {
                ...state,
                isAnimating: action.payload,
            };

        default:
            return state;
    }
};
export default reducerFunc