import React, { Fragment } from 'react'
import Header from 'containers/header/index'
import BannerLanding from 'containers/bannerLanding/index';
import ServicesLanding from 'containers/servicesLanding/index'
import AboutUsLanding from 'containers/aboutUsLanding/index';
import ReviewsLanding from 'containers/reviewsLanding/index';
import ContactUsLanding from 'containers/contactUsLanding/index';
import Footer from 'containers/footer/index';
import SectionSolution from 'components/sectionSolution/index';
// import dd from 'containers/'
import { useTranslation } from 'react-i18next';
import LoanCalculator from 'components/loanCalculator/index';

import useContentSectionPretEtudiant from './useContentSectionPretEtudiant'; // Import the custom hook
import useContentOpenBanqAccount from './useContentOpenBanqAccount'; // Import the custom hook

const Home = () => {
    const { t } = useTranslation();
    const contentSectionPretEtudiant = useContentSectionPretEtudiant(); // Get the translated content using the hook
    const contentOpenBanqAccount = useContentOpenBanqAccount(); // Get the translated content using the hook

    return (
        <Fragment>
            <Header styles="header-transparent header-style-2" />

            <BannerLanding />

            <div className='mt-5'>

                <SectionSolution content={contentSectionPretEtudiant} illustrationLeft={false} >
                    <LoanCalculator />
                </SectionSolution>
            </div>
            <div className='mt-5 mb-5'>

                <SectionSolution content={contentOpenBanqAccount} />
            </div>
            {!(process.env.REACT_APP_MODE_ACUISITION === "yes") &&
                <div className='mt-5'>
                    <ReviewsLanding />
                </div>}
            <div className='mt-5'>
                <ContactUsLanding />
            </div>
            <Footer />
        </Fragment>
    )
}

export default Home