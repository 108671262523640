import React, { useState, useContext } from 'react'

import { Form } from 'react-bootstrap';

import AnimatedStep from './AnimatedStep';
import GenericFormField from './GenericFormField';

import FormDataContext from './context/formDataContext';

const FormBody = () => {

    const [isAnimating, setIsAnimating] = useState(false);
    const [direction, setDirection] = useState('left');
    const formDataContext = useContext(FormDataContext);
    const { getSchemaStep, currentStep, fields, setField } = formDataContext;
    const step = getSchemaStep(currentStep);


    return (
        <div className="mb-4 overflow-hidden">
            <AnimatedStep isVisible={!isAnimating} direction={direction}>
                <Form className='mx-auto' style={{maxWidth:'400px'}}>
                    {step.fields.map((field, idx) => (
                        <GenericFormField
                            key={idx}
                            field={field}
                            value={fields[field.id]}
                            onChange={(v) => {
                                setField(field.id, v);
                            }}
                        />
                    ))}
                </Form>
            </AnimatedStep>
        </div>
    )
}

export default FormBody