import React from 'react';
import * as Icons from 'lucide-react';


const StepItemIndicator = ({ stepIndex, title, icon, currentPosition }) => {
    const IconComponent = Icons[icon] || Icons.Circle;

    const getVariant = () => {
        if (stepIndex < currentPosition) return 'success';
        if (stepIndex === currentPosition) return 'primary';
        return 'secondary';
    };

    const iconStyles = {
        width: '48px',
        height: '48px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: stepIndex <= currentPosition ? `var(--bs-${getVariant()})` : '#dee2e6',
        color: stepIndex <= currentPosition ? 'white' : '#6c757d',
        transition: 'all 0.3s ease-in-out',
    };

    return (
        <div className="text-center">
            <div style={iconStyles} className="mx-auto mb-2">
                {stepIndex < currentPosition ? (
                    <Icons.Check size={24} />
                ) : (
                    <IconComponent size={24} />
                )}
            </div>
            <div style={{ color: stepIndex === currentPosition ? 'var(--bs-primary)' : '#6c757d' }}>
                {title}
            </div>
        </div>
    );
};

export default StepItemIndicator;
