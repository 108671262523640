import actions from './actions';

const reduceFunction = (state, action) => {
    switch (action.type) {

        case actions.ADD_ALERT:
            //Chekc if alert already exist, we remove it to updae it
            state = state.filter((alert) => {
                return alert.id !== action.payload.id
            })
            return [...state, action.payload]

        case actions.UPDATE_ALERT:
            return state.map((alert) => {
                if (alert.id === action.id) {
                    return {
                        ...alert,
                        ...action.payload
                    }
                }
                else {
                    return alert
                }
            })
        case actions.REMOVE_ALERT:
            return state.filter((alert) => {
                return alert.id !== action.id
            })
        case actions.REMOVE_ALL:
            return []


        default:
            return state;
    }
};
export default reduceFunction;
