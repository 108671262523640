import React, { useContext } from 'react'
import FormDataContext from './context/formDataContext';



const FormHeader = () => {
    const formDataContext = useContext(FormDataContext);
    const { currentStep ,getSchemaStep } = formDataContext;


    const step = getSchemaStep(currentStep);
   
    return (
        <div className="text-center mb-4">
            <h4>{step.title}</h4>
            {step.description && (
                <p className="text-muted">{step.description}</p>
            )}
        </div>

    )
}

export default FormHeader