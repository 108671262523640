const form = {
    position: 3,
    id: "budgetInfo",
    title: "Informations de votre budget",
    description: "Entrez les informations de votre budget",
    icon: "HandCoins",
    fields: [
        {
            id: "revenuMensuel",
            type: "number",
            name: "revenuMensuel",
            label: "Revenu mensuel (€/mois)",
            placeholder: "Entrez votre revenu mensuel"
        },
        {
            id: "logement",
            type: "number",
            name: "logement",
            label: "Prévision du coût de votre logement (€/mois)",
            placeholder: "Entrez le coût estimé de votre logement"
        },
        {
            id: "transport",
            type: "number",
            name: "transport",
            label: "Prévision du coût de votre transport (€/mois)",
            placeholder: "Entrez le coût estimé de votre transport"
        },
        {
            id: "nourriture",
            type: "number",
            name: "nourriture",
            label: "Prévision du coût de votre nourriture ($/mois)",
            placeholder: "Entrez le coût estimé de votre nourriture"
        },
        {
            id: "loisir",
            type: "number",
            name: "loisir",
            label: "Prévision du coût de vos loisirs (€/mois)",
            placeholder: "Entrez le coût estimé de vos loisirs"
        },
        {
            id: "fourniture",
            type: "number",
            name: "fourniture",
            label: "Prévision du coût des fournitures (€/mois)",
            placeholder: "Entrez le coût estimé des fournitures"
        },
        {
            id: "fraisScolarite",
            type: "number",
            name: "fraisScolarite",
            label: "Prévision des frais de scolarité (€/mois)",
            placeholder: "Entrez le montant estimé des frais de scolarité"
        }
    ]
};

export default form;
