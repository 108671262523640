import React, { Fragment, useContext } from 'react'
import StepItemIndicator from './StepItemIndicator'
import { ProgressBar, Row, Col } from 'react-bootstrap';

import FormDataContext from './context/formDataContext';

const StepsIndicator = () => {

    const formDataContext = useContext(FormDataContext);

    const { schema, currentStep, getSchemaStepPostion } = formDataContext;


    const currentPosition = getSchemaStepPostion(currentStep);
    const progress = schema.steps.length > 0 ? (currentPosition / (schema.steps.length )) * 100 : 1;

    return (
        <Fragment>
            <Row className="mb-4 align-items-center">
                {schema.steps.map((step, index) => (
                    <Col key={step.id}>
                        <StepItemIndicator
                            stepIndex={index+1}
                            title={step.title}
                            icon={step.icon}

                            currentPosition={currentPosition}
                        />
                    </Col>
                ))}

                <ProgressBar
                    now={progress}
                    className="mb-4"
                    variant={currentPosition === schema.steps.length ? "success" : "primary"}
                />
            </Row>

        </Fragment>
    )
}

export default StepsIndicator