import React from 'react';
import { Link } from 'react-router-dom';
import ScrollTopButton from 'components/buttonScrollToTop/index';
import { useTranslation } from 'react-i18next';
import { CiYoutube } from "react-icons/ci";
import { FaFacebook } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <>
            <footer className="eduvibe-footer-one edu-footer footer-style-default">
                <div className="footer-top">
                    <div className="container eduvibe-animated-shape">
                        <div className="row g-5">
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="edu-footer-widget">
                                    <div style={{
                                        width: '260px',
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Link to={process.env.PUBLIC_URL + "/"}>
                                            <img className="logo-light" src="/images/logo/logo-studethic-white.svg"

                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain'
                                                }}
                                                alt="Footer Logo" />
                                        </Link>
                                    </div>
                                    <p className="description">{t('footer.about.description')}</p>
                                    <ul className="social-share">
                                        <li><a href="#">  <CiYoutube size={32} /></a></li>
                                        <li><a href="#">  <FaFacebook size={32} /></a></li>
                                        <li><a href="#">  <RiTwitterXFill size={32} /></a></li>
                                        <li><a href="#">  <FaLinkedin size={32} /></a></li>
                                        <li><a href="#">  <FaInstagramSquare size={32} /></a></li>
                                        <li><a href="#">  <FaTiktok size={32} /></a></li>

                                    </ul>
                                </div>
                            </div>

                            {!(process.env.REACT_APP_MODE_ACUISITION === "yes") && <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget explore-widget">
                                    <h5 className="widget-title">{t('footer.explore.title')}</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to="/about-us-1"><i className="icon-Double-arrow"></i>{t('footer.explore.aboutUs')}</Link></li>
                                            <li><Link to="/blog-standard"><i className="icon-Double-arrow"></i>{t('footer.explore.blog')}</Link></li>
                                            <li><Link to="/faq"><i className="icon-Double-arrow"></i>{t('footer.explore.faq')}</Link></li>
                                            <li><Link to="/privacy-policy"><i className="icon-Double-arrow"></i>{t('footer.explore.privacyPolicy')}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}

                            {!(process.env.REACT_APP_MODE_ACUISITION === "yes") && <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget quick-link-widget">
                                    <h5 className="widget-title">{t('footer.usefulLinks.title')}</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to="/contact-us"><i className="icon-Double-arrow"></i>{t('footer.usefulLinks.contactUs')}</Link></li>
                                            <li><Link to="/instructor-details/james-carlson"><i className="icon-Double-arrow"></i>{t('footer.usefulLinks.instructorProfile')}</Link></li>
                                            <li><Link to="/event-details/1"><i className="icon-Double-arrow"></i>{t('footer.usefulLinks.eventDetails')}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}

                            {!(process.env.REACT_APP_MODE_ACUISITION === "yes") && <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget">
                                    <h5 className="widget-title">{t('footer.contact.title')}</h5>
                                    <div className="inner">
                                        <div className="widget-information">
                                            <ul className="information-list">
                                                <li><i className="icon-map-pin-line"></i>{t('footer.contact.address')}</li>
                                                <li><i className="icon-phone-fill"></i><a href="tel: + 1 (237) 382-2839">{t('footer.contact.phone')}</a></li>
                                                <li><i className="icon-mail-line-2"></i><a target="_blank" href="mailto:yourmailaddress@example.com">{t('footer.contact.email')}</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-21-01.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-35.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area copyright-default">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <p>{t('footer.copyright.text')} <a href="#">{t('footer.copyright.link')}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <ScrollTopButton /> */}
        </>
    )
}

export default Footer;