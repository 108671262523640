//React
import React, {  useReducer } from 'react';



import formDataReducer from './formDataReducer';
import formDataContext from './formDataContext';
import actions from './actions';
import _ from 'lodash';


const generateInitialStateFromSchema = (schema) => {
    const state = {
        pending: false,
        currentStep: schema.steps.find((step) => {
            return step.position === 1
        }).id,
        schema: schema,
        isAnimating: false,
        alerts: [],//TODO later,
        fields: {}
    }

    for (const step of schema.steps) {
        for (const field of step.fields) {
            state.fields[field.id] = ''//To initialize it
        }

    }

    console.log('state==>', state);
    return state;
}


const FormDataState = (props) => {


    const { schema, onSubmit } = props;


    const [state, dispatch] = useReducer(formDataReducer, generateInitialStateFromSchema(schema));




    //Dispatchers
    const setField = (id, value) => {
        dispatch({ type: actions.SET_FIELD, id: id, payload: value });
    }


    const setCurrentStep = (value) => {
        dispatch({ type: actions.SET_CURRENT_STEP, payload: value });

    }




    const setPending = (value) => {
        dispatch({ type: actions.SET_PENDING, payload: value });

    }

    const setIsAnimating = (value) => {
        dispatch({ type: actions.SET_IS_ANIMATING, payload: value });

    }



    //Functions
    const submit = () => {
        onSubmit(state.fields);
    }


    const nextStep = (stepId) => {
        console.log('stepId++>', stepId)

        const position = getSchemaStepPostion(stepId)
        console.log('position==>', position);

        const step = getSchemaStepFromPosition(position + 1);
        if (step) {
            setCurrentStep(step.id)
        }

    }

    const previousStep = (stepId) => {


        const position = getSchemaStepPostion(stepId)

        const step = getSchemaStepFromPosition(position - 1);
        if (step) {
            setCurrentStep(step.id)
        }


    }


    const getSchemaStepFromPosition = (position) => {
        return state.schema.steps.find((step) => {
            return position === step.position
        })
    }


    const getSchemaStep = (stepId) => {
        return state.schema.steps.find((step) => {
            return stepId === step.id
        })

    }

    const getSchemaStepPostion = (stepId) => {

        return getSchemaStep(stepId).position;
    }





    return (
        <formDataContext.Provider
            value={{
                fields: state.fields,
                pending: state.pending,
                schema: state.schema,
                currentStep: state.currentStep,
                setField: setField,
                setPending: setPending,
                setCurrentStep: setCurrentStep,
                setIsAnimating: setIsAnimating,
                submit: submit,
                nextStep: nextStep,
                previousStep: previousStep,
                getSchemaStep: getSchemaStep,
                getSchemaStepPostion: getSchemaStepPostion

            }}
        >
            {props.children}
        </formDataContext.Provider>
    );
};

export default FormDataState;
