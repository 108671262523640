import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from 'components/scrollToTop/index';
import Home from 'views/home/index'
import PretEtudiant from 'views/pretEtudiant/index';

import { ThemeProvider } from 'react-bootstrap';
import Alerts from 'components/alerts'

// Import Css Here 
import 'assets/scss/style.scss';
import AlertsState from './contexts/alerts/AlertsState';



function App() {

  const [authenticated, setAuthenticated] = useState(true);
  const [password, setPassword] = useState(false);

  const correctPassword = 'studethic123'; // Replace with your desired password

  const handleLogin = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setAuthenticated(true);
    } else {
      alert('Incorrect password!');
    }
  };
  return (
    <ThemeProvider theme={{
      primary: 'red',
    }}>

      <AlertsState>
        <Alerts />

        <div>
          {!authenticated ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <div className="text-center w-25">
                <h5>Enter Password</h5>
                <form onSubmit={handleLogin}>
                  <input
                    type="password"
                    className=" mb-3"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button type="submit" className="btn edu-btn w-100">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          ) : (
            <Router>
              <ScrollToTop>
                <Routes>
                  <Route exact path={`/`} element={<Home />} />
                  <Route exact path={`/pret-etudiant`} element={<PretEtudiant />} />
                </Routes>
              </ScrollToTop>
            </Router>
          )}
        </div>
      </AlertsState>
    </ThemeProvider>
  );
}

export default App;
