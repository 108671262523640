import schemaAcademicInfo from './schemaAcademicInfo';
import schemaPersonalInfo from './schemaPersonalInfo';
import schemaBudgetInfo from './schemaBudgetInfo';

const schema = {
    id: 'formLeadStudentCredit',
    title: '',
    descriptions: '',
    steps: [{
        ...schemaPersonalInfo
    },
    {
        ...schemaAcademicInfo
    },
    {
        ...schemaBudgetInfo
    }]



}


export default schema;