import React, { useState, useContext } from 'react'
import { Form, Button } from 'react-bootstrap';
import './style.css'
import PhoneInput from 'react-phone-number-input'
import parsePhoneNumber, {
    isValidPhoneNumber,
} from 'libphonenumber-js'

import useSubscribers from 'hooks/useSubscribers/index';
import Spinner from 'components/spinner';


import Modal from 'react-bootstrap/Modal';

const SimpleModal = ({ show, onHide, children }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            onClick={(e) => e.stopPropagation()}
        >
            <Modal.Body>
                <button
                    type="button"
                    className="btn-close position-absolute top-0 end-0 m-3"
                    onClick={onHide}
                    aria-label="Close"
                />
                {children}
            </Modal.Body>
        </Modal>
    );
};

const SubscriptionForm = () => {
    const [subscriber, setSubscriber] = useState({
        email: '',
        phone: '',
        acceptTerms: false
    });
    const [displayTerms, setDisplayTerms] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidPhone, setIsValidPhone] = useState(true);
    const [isAccepted, setIsAccepted] = useState(true);

    const { createSubscriber, pending } = useSubscribers();
    const handleSubmit = async (e) => {
        e.preventDefault();
        //Validatin

        const isValidEmail = validateEmail(subscriber.email)
        if (!isValidEmail) {
            console.log('invalid email')
            setIsValidEmail(false);
            return;
        }

        const isValidPhone = isValidPhoneNumber(subscriber.phone)

        if (!isValidPhone) {
            console.log('invalid phone')
            setIsValidPhone(false);
            return;
        }

        if (!subscriber.acceptTerms) {
            setIsAccepted(false);
            return;
        }
        console.log('subscriber.phone=>', subscriber.phone)

        const phoneNumberParsed = parsePhoneNumber(subscriber.phone || '0')
        await createSubscriber({
            email: subscriber.email,
            phone: {
                number: phoneNumberParsed.number,
                countryCode: phoneNumberParsed.country
            }
        })



    };


    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const handleChangeEmail = (e) => {
        setIsValidEmail(true)

        const email = e.target.value;


        setSubscriber({ ...subscriber, email });
    }

    const handleChangePhone = (phone) => {
        setIsValidPhone(true)

        setSubscriber({ ...subscriber, phone });
    };
    const handleChangeAccept = (e) => {
        setIsAccepted(true);

        setSubscriber({ ...subscriber, acceptTerms: e.target.checked });


    }
    return (
        <div className="newsletters-subscription max-w-md mx-auto p-4">

            <Form >
                <Form.Group className="mb-4 position-relative">

                    {/* <div class="d-flex flex-column gap-3"> */}

                    {!isValidEmail && (
                        <Form.Text className="text-danger">
                            Veuillez entrer une adresse email valide
                        </Form.Text>
                    )}
                    <div className="d-flex align-items-center  justify-content-center w-100 w-md-75 mb-5">
                        <div className="email-icon-container me-3">
                            <i className="fas fa-envelope  text-muted" style={{
                                fontSize: '3.1rem'
                            }}></i>
                        </div>

                        <Form.Control
                            type="email"
                            placeholder="Votre email"
                            value={subscriber.email}
                            onChange={handleChangeEmail}
                            // className="input-email form-control-lg"
                            className={`input-email form-control-lg ${!isValidEmail ? 'is-invalid' : ''}`}

                        />

                    </div>


                    {!isValidPhone && (
                        <Form.Text className="text-danger">
                            Veuillez entrer un  numéro de télephone valide
                        </Form.Text>
                    )}


                    <div className="d-flex align-items-center w-100 w-md-75 mb-2">

                        <PhoneInput
                            placeholder="Votre numéro de télephone"
                            defaultCountry="FR"
                            value={subscriber.phone}
                            countryCallingCodeEditable={true}

                            onChange={handleChangePhone}
                            className={`form-control-lg w-100 mx-auto phone-input p-0 ${!isValidPhone ? 'is-invalid' : ''}`}// Larger height, smaller width, centered

                        />
                    </div>


                    {!isAccepted && (
                        <Form.Text className="text-danger">
                            Veuillez accepter la checkbox ci dessous
                        </Form.Text>
                    )}

                    <div className="d-flex justify-content-start align-items-center w-75 mb-3">

                        <Form.Check
                            type="checkbox"
                            id="acceptUpdates"
                            label={<span className="small text-muted lh-1">J'accepte d'être informé(e) par e-mail des nouveautés de studethic</span>}
                            className="text-small text-muted text-small custom-checkbox lh-1"
                            onChange={handleChangeAccept}

                        />
                    </div>

                    <div className="d-flex align-items-center w-100 w-md-75 mb-2 position-relative" >

                        <div className='d-flex   justify-content-center w-100'>

                            <button className={'edu-btn mb-3 btn-medium text-center   w-60'} onClick={handleSubmit}>
                                {"S'inscrire à la newsletter"}
                            </button>
                        </div>
                    </div>

                    <div className="d-flex align-items-center w-100 w-md-75  position-relative" >
                        <p className='small lh-1 text-primary text-decoration-underline  pointer    ' style={{ cursor: 'pointer' }} onClick={() => {
                            setDisplayTerms(true)
                        }}>
                            Informations sur le traitement de vos données personnelles
                        </p>

                    </div>


                    {/* </div> */}
                </Form.Group>





            </Form>

            <SimpleModal show={displayTerms} onHide={() => {
                setDisplayTerms(false);
            }}>
                <h4 className='me-3'>
                    Informations sur le traitement de vos données personnelles
                </h4>
                <p>
                    Vos données sont collectées par Studethic dans le but de vous offrir un accès à nos différents services (création de compte, demande de crédit, suivi de vos dossiers, gestion de votre budget étudiant, et services associés).
                    Pour en savoir plus sur la gestion de vos données personnelles, exercer vos droits d’opposition, d’accès, d’effacement, de limitation, de rectification, ainsi que le droit à la portabilité de vos données ou définir le sort de vos données après votre décès, vous pouvez consulter notre politique de confidentialité.
                    Vous avez également la possibilité d’introduire une réclamation auprès de la Cnil.

                </p>
            </SimpleModal>

            {pending &&
                <Spinner width='90' height='90' message='loading...' />}
        </div>
    );
};

export default SubscriptionForm